<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.report">
			<template #title>Benchmark Results By User</template>
			<template #title-actions-shown>

				<v-btn color="success" elevation="2" small @click="getData" :loading="loading">
					Get Data
				</v-btn>
			</template>
			<v-row>
				<v-col cols="12" lg="4">
					<v-select v-model="report_params.corporate_id" :items="corporates" item-text="name" item-value="id" label="Corporate" clearable></v-select>
				</v-col>
			</v-row>

			<v-data-table :headers="headers" :items="reportData" item-key="id" :search="search">
				<template v-slot:item.benchmark_last_completed_at="{ item }">
					{{ item.benchmark_last_completed_at | date_to_human }}
				</template>
				<template v-slot:footer.prepend>
					<v-row>
						<v-col cols="auto">
							<ti-export-to-csv :json-data="reportData" filename="Benchmark Results"/>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>

import {VuePivottableUi} from 'vue-pivottable'
import 'vue-pivottable/dist/vue-pivottable.css'
import {sync} from "vuex-pathify";

export default {
	name: "BenchmarkResult",
	components: {VuePivottableUi},
	computed: {
		corporates: sync('corporates/corporates'),
	},
	mounted() {
		this.init()
	},
	data: () => ({
		report_params: {
			corporate_id: null,
		},
		search: '',
		loading: false,
		reportData: [
			{
				first_name: '',
				last_name: '',
				email: '',
				topic_name: '',
				industry: '',
				seniority: '',
				key_focus: '',
				score: 0
			}
		],
		headers: [
			{
				text: 'First name',
				align: 'start',
				sortable: true,
				value: 'first_name',
			},
			{
				text: 'Last name',
				align: 'start',
				sortable: true,
				value: 'last_name',
			},
			{
				text: 'Email',
				align: 'start',
				sortable: true,
				value: 'email',
			},
			{
				text: 'Date',
				align: 'start',
				sortable: true,
				value: 'benchmark_last_completed_at',
			},
			{
				text: 'Topic',
				align: 'start',
				sortable: true,
				value: 'topic_name',
			},
			{
				text: 'Score',
				align: 'start',
				sortable: true,
				value: 'score',
			},
		],
	}),
	methods: {
		init() {
			this.$store.set('corporates/getCorporates!', null);
		},
		getData() {
			this.loading = true;
			this.$api.reports.benchmarkResultsUser({report_params: this.report_params})
				.then(response => this.reportData = response.data)
				.catch(error => console.log(error))
				.finally(() => this.loading = false)
		}
	}
}
</script>